<template>
  <div class="row">

    <div class="col-12">

      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">{{ informationData.title }} List</h4>
        </div>

        <div>
          <general-data-table
            ref="table"
            :method="'POST'"
            :row-key="'id'"
            :key="componentKey"
            :api-url="informationData.url+'/view-children/'+$route.params['id']">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">
            </el-table-column>

            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['children/view'])"
                             v-tooltip.top-center="'View Info'"
                             class="btn-success btn-simple btn-link"
                             :to="'/children/view/'+scope.row.id">
                  <i class="fa fa-eye"></i></router-link>
              </template>
            </el-table-column>
          </general-data-table>
        </div>

      </card>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {DeleteModal as DeleteModal, NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    NLPagination,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    GeneralDataTable,
    FgSelect,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'First Name', value: 'first_name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'last_name', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      componentKey: 0,
      informationData: {
        title: 'Children',
        url: 'parents',
      }
    }

  },
  mounted() {
    // this.loader = this.$loading.show({
    //   container: this.$refs.templateForm
    // });

    this.extractTypeFromUrl();
    this.id = this.$route.params['id'];

    // this.loader.hide();
    if (this.id !== undefined) {
    }
  },
  methods: {
    extractTypeFromUrl() {
      const url = window.location.href
      const segments = url.split('/')
      const schoolIndex = segments.findIndex(segment => segment === 'schools')
      const parentIndex = segments.findIndex(segment => segment === 'parents')
      if (schoolIndex !== -1 && segments.length > schoolIndex + 1) {
        this.informationData.title = "Student";
        this.informationData.url = "schools";
        this.componentKey++;
      }else if(parentIndex !== -1 && segments.length > parentIndex + 1){
        console.log('parent')
    }
  }
  },

}
</script>
